import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import * as Models from "../../models/models";
import { handleError } from "../../services/ErrorService";
import SeasonService from "../../services/SeasonService";
import "./GroupAdmin.scss";

interface Props {
  season: Models.ISeasonSummary;
}

export const GroupAdmin = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<Models.SeasonGroups>();
  const [selectedTurtleId, setSelectedTurtleId] = useState<number>();

  useEffect(() => {
    setIsLoading(true);
    SeasonService.getGroups(props.season.year)
      .then((groups) => {
        setGroups(groups);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
        setIsLoading(false);
      });
  }, [props.season]);

  const createGroups = async () => {
    setIsLoading(true);
    try {
      const groups = await SeasonService.createGroups(props.season.id);
      setGroups(groups);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const copy = () => {
    let text = "";
    groups?.gameGroups.forEach((day) => {
      text += `Day ${day.game.dayNumber} ${day.game.date} @ ${day.game.tee.course.name}\r\n`;
      for (let x = 0; x < day.groups.length; x++) {
        const group = day.groups[x];
        for (let y = 0; y < 4; y++) {
          if(group.turtles.length >= y) {
            text += `${group.turtles[y]?.fullName || ""}\t`;
          } else {
            text += " \t";
          }
        }
        text += "\r\n";
      }
    });
    navigator.clipboard.writeText(text);
  };

  const selectTurtle = (turtle: Models.ITurtle) => {
    setSelectedTurtleId(turtle.id);
  };

  return (
    <div className="group-admin">
      <Backdrop sx={{ color: "rgb(25, 118, 210)", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button size="small" onClick={createGroups}>
        Create Groups
      </Button>

      <Button size="small" onClick={copy}>
        Copy to Clipboard
      </Button>

      <div className="groups">
        {groups &&
          groups.gameGroups.map((day) => (
            <div key={day.game.id} className="day">
              <div>Day {day.game.dayNumber}</div>
              <div className="day-groups">
                {day.groups.map((group) => (
                  <div key={`${day.game.id}-${group.groupNumber}`} className="group">
                    {group.turtles
                      .filter((t) => t !== null)
                      .map((t) => (
                        <span
                          key={t.id}
                          className={"turtle-name" + (selectedTurtleId === t.id ? " turtle--selected" : "")}
                          onClick={() => selectTurtle(t)}
                        >
                          {t.fullName}
                        </span>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
